<template>
  <div>
    <div></div>
    <div>
      <vs-row vs-justify="center">
        <vs-row
          type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="12"
          vs-sm="4"
          vs-xs="12"
        >
          <div class="back-grewond2">
            <vs-row
              vs-justify="flex-center"
              vs-lg="12"
              vs-sm="4"
              vs-xs="12"
              style="padding: 10%"
            >
              <vs-row vs-lg="12" vs-sm="4" vs-xs="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                </vs-col>
                <vs-col
                  style="padding: 2%"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                >
                  <img src="../../assets/images/doclnia.png" />
                </vs-col>
              </vs-row>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="12"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
              >
                <h1 style="color: aliceblue">CONTACT US</h1>
              </vs-row>
            </vs-row>
          </div>
        </vs-row>
      </vs-row>

      <!--  -->

      <div>
        <vs-row
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-lg="6"
          vs-md="12"
          vs-sm="12"
          vs-xs="12"
          style="padding-top: 20px; margin: 1px"
        >
          <vs-col
            style="overflow: visible; margin-top: -5%"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-md="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-card
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-md="12"
              vs-sm="12"
              vs-xs="12"
            >
              <vs-row
                vs-lg="10"
                vs-justify="center"
                vs-type="flex"
                vs-md="12"
                vs-sm="12"
                vs-xs="12"
              >
                <vs-col vs-lg="8" vs-md="12" vs-sm="12" vs-xs="9">
                  <!--  -->
                  <vs-input
                    v-validate="'required|min:5'"
                    data-vv-validate-on="blur"
                    @keydown.space.prevent
                    label="First Name"
                    v-model="UserName"
                  />

                  <vs-input
                    v-validate="'required|min:5'"
                    data-vv-validate-on="blur"
                    @keydown.space.prevent
                    label="Last Name"
                    v-model="LastName"
                  />

                  <vs-input
                    v-validate="'required|min:5'"
                    data-vv-validate-on="blur"
                    @keydown.space.prevent
                    label="Email"
                    name="Email"
                    v-model="Email"
                  />

                  <vs-input
                    v-validate="'required|min:5'"
                    data-vv-validate-on="blur"
                    @keydown.space.prevent
                    label="Phone Number"
                    name="PhoneNumber"
                    v-model="PhoneNumber"
                  />

                  <vs-input
                    v-validate="'required|min:5'"
                    data-vv-validate-on="blur"
                    @keydown.space.prevent
                    label="Message"
                    name="Message"
                    v-model="Message"
                  />

                  <!--  -->
                  <vs-button color="success" type="filled">Success</vs-button>
                </vs-col>
              </vs-row>
            </vs-card>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../../components/footer/Footer.vue";

export default {
  data: {},
  components: {
    Footer,
  },
};
</script>
<style>
.back-grewond2 {
  background-image: url("../../assets/images/4.png");
  width: 100%;
  background-repeat: round;
  background-attachment: local;
  background-size: cover;
}
.vs-con-input-label {
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  padding: 3%;
}
.vs-input--label {
  font-size: large;
  color: rgba(0, 0, 0, 0.7);
}
/* .vs-input--label {
    font-size: 71%;
    color: #999;
} */
/*  */

/*  */
</style>
